<template>
  <div style="width: 100vw; height: 100vh; text-align:center">
    <h1>404</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>